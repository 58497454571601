import * as ReactTable from "react-table";
import TData from "./t_data";
import TRow from "./t_row";
import { colors } from "../GlobalStyle/GlobalStyle";
import { useCallback } from "react";

export interface IRow<P extends {}> extends ReactTable.Row<P> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}
export interface TRenderRowProps<P extends {}> {
  prepareRow: (row: IRow<P>) => void;
  handleSelectRow?: (row?: IRow<P>) => void;
  handleContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedRow?: IRow<P>;
  flexIndex?: number;
  isCustomSelect?: boolean;
  row: IRow<P>;
  data: IRow<P>[];
  isGroupColor?: boolean;
}

function TRenderRow<P extends {}>({
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  flexIndex,
  row,
  data,
  isGroupColor,
  isCustomSelect = false
}: TRenderRowProps<P>) {
  let backgroundColor = row.isSelected ? colors.green : "none";
  // 키값이 같은것들을 묶어서 똑같은 색으로 표현해주기
  // 현재는 통계의 근무현황 group table으로만 셋팅처리
  if (isGroupColor) {
    const keyList: string[] = data.map(item => {
      const original = item.original as any;
      return original.periodInformation;
    });
    let newColorGroup: { preColor: string; currentColor: string }[] = [];
    keyList.forEach((item, index) => {
      if (index !== 0) {
        const pre = keyList[index - 1];
        // 이전값과 현재값이 다를때 색 셋팅
        if (pre !== item) {
          const currentColor =
            newColorGroup[index - 1].currentColor === colors.white
              ? colors.grey
              : colors.white;
          newColorGroup.push({
            preColor: newColorGroup[index - 1].currentColor,
            currentColor
          });
          return colors.grey;
        } else {
          // 이전값과 현재값이 같을때 색 셋팅
          newColorGroup.push({
            preColor: newColorGroup[index - 1].currentColor,
            currentColor: newColorGroup[index - 1].currentColor
          });
        }
      } else {
        // 현재값의 index가 0일때
        newColorGroup.push({
          preColor: colors.white,
          currentColor: colors.white
        });
      }
    });
    backgroundColor = newColorGroup[row.index].currentColor;
  }
  prepareRow(row);
  const multiSelect = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!isCustomSelect) {
        const contextButton = 2;
        if (event.type === "contextmenu" || event.button === contextButton) {
          event.preventDefault();
        }
        if (row.toggleRowSelected) {
          if (
            row.isSelected &&
            (event.type !== "contextmenu" || event.button !== contextButton)
          ) {
            data.forEach(item => {
              if (item.isSelected && item.toggleRowSelected) {
                item.toggleRowSelected(false);
              }
            });
            row.toggleRowSelected(false);
          } else if (selectedRow && (event.metaKey || event.ctrlKey)) {
            row.toggleRowSelected(true);
            if (
              (event.type === "contextmenu" ||
                event.button === contextButton) &&
              handleContextMenu
            ) {
              handleContextMenu(event);
            }
          } else if (selectedRow && event.shiftKey) {
            data.forEach(item => {
              if (
                parseInt(selectedRow.id) < parseInt(item.id) &&
                parseInt(item.id) <= parseInt(row.id) &&
                item.toggleRowSelected
              ) {
                item.toggleRowSelected(true);
                if (
                  (event.type === "contextmenu" ||
                    event.buttons === contextButton) &&
                  handleContextMenu
                ) {
                  handleContextMenu(event);
                } else if (handleSelectRow) {
                  handleSelectRow(row);
                }
              }
              if (
                parseInt(item.id) < parseInt(selectedRow.id) &&
                parseInt(row.id) <= parseInt(item.id) &&
                item.toggleRowSelected
              ) {
                item.toggleRowSelected(true);
                if (
                  (event.type === "contextmenu" ||
                    event.buttons === contextButton) &&
                  handleContextMenu
                ) {
                  handleContextMenu(event);
                }
              }
              if (
                (event.type !== "contextmenu" ||
                  event.buttons !== contextButton) &&
                handleSelectRow
              ) {
                handleSelectRow(row);
              }
            });
          } else {
            if (
              (event.type === "contextmenu" ||
                event.buttons === contextButton) &&
              handleContextMenu
            ) {
              if (row.isSelected) {
                handleContextMenu(event);
              } else {
                data.forEach(item => {
                  if (item.isSelected && item.toggleRowSelected) {
                    item.toggleRowSelected(false);
                  }
                });
                row.toggleRowSelected(true);
                handleContextMenu(event);
              }
            } else {
              if (selectedRow) {
                data.forEach(item => {
                  if (item.isSelected && item.toggleRowSelected) {
                    item.toggleRowSelected(false);
                  }
                });
              }
              row.toggleRowSelected(true);
              if (handleSelectRow) {
                handleSelectRow(row);
              }
            }
          }
        }
      }
    },
    [row, handleContextMenu, selectedRow, isCustomSelect]
  );
  console.log(backgroundColor);
  return (
    <TRow {...row.getRowProps()} className="tr">
      {row.cells.map((cell, rowIndex) => (
        <TData
          className="td"
          {...cell.getCellProps()}
          key={`${row.id} ${Math.random() * 10}`}
          id={`${row.id}-context-menu`}
          onContextMenu={multiSelect}
          onClick={multiSelect}
          $isFlex={flexIndex === rowIndex}
          $isLastFlex={flexIndex && flexIndex >= 0 ? false : true}
          $isSelected={row.isSelected}
          $backgroundColor={backgroundColor}
        >
          {cell.render("Cell")}
        </TData>
      ))}
    </TRow>
  );
}

export default TRenderRow;
